<template>
  <div class="dropdown" :class="className" :id="`dropdown-${_uid}`">
    <Button type="button" @click="open = !open" :icon="(items.length < 2 && !placeholder) ? null : open ? 'chevron-up' : 'chevron-down'" :primary="primary" :success="success" :fill="fill" icon-last>
      <slot v-if="!label" />
      <span v-else>{{ label }}</span>
    </Button>
    <FormError v-if="v && v.$error">Selecteer een optie</FormError>
    <ul class="dropdown-options" v-if="open">
      <li v-if="clear && value" @click="dropdownChanged(clear)"><span>{{ clear.label }}</span></li>
      <li v-if="placeholder" @click="dropdownChanged(placeholder)"><span>{{ placeholder.label }}</span></li>
      <li v-for="(item, index) in items" :key="index" :class="{'divider': item.divider}">
        <span @click="dropdownChanged(item)" v-if="!item.items">{{ item.value }}</span>
        <span v-if="item.items">{{ item.value }}</span>
        <ul v-if="item.items">
          <li v-for="(child, childIndex) in item.items" :key="childIndex"><span @click="dropdownChanged(child, item)">{{ child.value }}</span></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import Button from './Button'
import FormError from "./FormError.vue";
export default {
  name: 'Dropdown',
  data () {
    return {
      open: false,
      localLabel: false
    }
  },
  components: {
    FormError,
    Button
  },
  props: {
    items: {
      type: Array
    },
    right: Boolean,
    fill: Boolean,
    primary: Boolean,
    success: Boolean,
    placeholder: [Object],
    clear: [Object],
    value: [Object, Number, String, Array, Boolean],
    v: Object
  },
  mounted () {
    document.addEventListener('open-dropdown', e => {
      // If uid does not match uid in detail on event, close this one
      if (this.open && e.detail !== this._uid) {
        this.open = false
      }
    })

    document.addEventListener('click', (e) => {
      if (this.open) {
        const clickTarget = document.querySelector(`#dropdown-${this._uid}`)
        if (!e.composedPath().includes(clickTarget)) {
          this.open = false
        }
      }
    })
  },
  computed: {
    className () {
      const classes = []
      if (this.right) {
        classes.push('right')
      }
      if (this.v && this.v.$error) {
        classes.push('error')
      }
      return classes
    },
    label () {
      if (this.value && this.value.value) {
        return this.value.value
      } else if (this.value) {
        return this.value
      } else if (this.value === false) {
        return false
      } else if (!this.localLabel && this.placeholder) {
        return this.placeholder.label
      }
      return this.localLabel
    }
  },
  methods: {
    dropdownChanged (item, parent) {
      this.localLabel = item.value
      if (typeof item.action === 'function') {
        item.action(item.key)
      } else if (parent && typeof parent.action === 'function') {
        parent.action(item.key)
      } else {
        if ('key' in item) {
          this.$emit('change', item.key)
        } else {
          this.$emit('change', false)
        }
      }
      this.open = false
    }
  },
  watch: {
    open () {
      if (this.open) {
        document.dispatchEvent(new CustomEvent('open-dropdown', { detail: this._uid }))
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
  width: max-content;
  max-width: 100%;
}
.dropdown-options {
  background: var(--primary-bg);
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  color: #000;
  position: absolute;
  left: 0;
  border-radius: var(--border-radius);
  top: 3rem;
  cursor: pointer;
  z-index: 3;
  width: max-content;
  max-width: 85vw;
  max-height: min(350px, 85vh);
  overflow-y: scroll;
  min-width: 100%;

  li {
    ul span {
      padding-left: 1.5rem;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--gray);
    }
  }

  > li {
    border-bottom: 2px solid var(--gray) !important;
  }

  > li:has(+ .divider) {
    border-bottom: 2px solid var(--primary-color) !important;
  }

  span {
    display: block;
    padding: 0.8rem 1.5rem;
    &:hover {
      background: var(--gray);
    }
  }
}

.dropdown.right .dropdown-options {
  left: auto;
  right: 0;
}
.dropdown.error button {
  border: 1px solid;
  border-color: var(--danger-color)
}
</style>
