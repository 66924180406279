<template>
  <div :class="{ 'form-group': !slim, inline, 'has-error': v && v.$error }" :id="id">
    <label :for="forId" v-if="title">{{ title }} <span class="text-primary" v-if="titleSuffix">{{ titleSuffix }}</span> <span v-if="tooltipText"><a class="has-tooltip"><i class="fal fa-info-circle text-primary"></i><span class="tooltip"><strong class="mb-2 d-block"></strong>{{ tooltipText }}</span></a></span> <span v-if="isRequired" class="required">*</span></label>
    <label v-if="shortTitle">{{ shortTitle }}</label>
    <FormHelp v-if="help">{{ help }}</FormHelp>
    <slot />
    <FormError v-if="v && v.$error && !hideError" :v="v" />
  </div>
</template>

<script>
import FormError from './FormError'
import FormHelp from './FormHelp'
export default {
  name: 'FormGroup',
  components: {
    FormHelp,
    FormError
  },
  props: {
    title: String,
    titleSuffix: String,
    tooltipText: String,
    required: [Boolean, Number],
    id: String,
    forId: String,
    inline: Boolean,
    v: Object,
    help: String,
    hideError: Boolean,
    slim: Boolean,
    shortTitle: String
  },
  computed: {
    isRequired () {
      return this.required || (this.v && 'required' in this.v)
    }
  }
}
</script>

<style lang="scss">
.form-group {
  margin-bottom: 1rem;

  &.inline {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &.has-error input {
    border-color: var(--danger-color);
  }

  &.is-success input {
    border-color: var(--success-color);
  }
}
a.has-tooltip {
  position: relative;
  cursor: pointer;
}
a.has-tooltip .tooltip {
  display: none;
  position: absolute !important;
  top: -6px !important;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 40%) !important;
  background: #FFFBB0 !important;
  padding: 15px 20px !important;
  border-radius: 2px !important;
  border: 0px solid #CFCF33 !important;
  text-align: left !important;
  width: 300px !important;
  white-space: normal !important;
  color: #444 !important;
  line-height: 15px !important;
  opacity: 1;
  font-size: 12px;
}
a.has-tooltip:hover .tooltip {
  display: block;
  left: 115%;
  z-index: 1;
}
a.has-tooltip:hover {
  display: inline;
  opacity: 1;
}
</style>
