<template>
  <FormGroup :title="label" :shortTitle="shortTitle" :help="help" :required="required" :v="v" :forId="id">
    <textarea :value="value" :class="{ error: v && v.$error }" :id="id" :placeholder="placeholder" @blur="handleBlur" @input="$emit('input', $event.target.value)" />
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
export default {
  name: 'InputArea',
  components: {
    FormGroup
  },
  props: {
    value: String,
    v: Object,
    label: String,
    help: String,
    required: Boolean,
    placeholder: String,
    id: String,
    shortTitle: String
  },
  methods: {
    handleBlur () {
      if (this.v) {
        this.v.$touch()
      }
    }
  }
}
</script>

<style lang="scss">
textarea {
  border: 1px solid var(--gray-darker);
  padding: 0.6rem 1rem;
  border-radius: var(--border-radius);
  outline: 0;
  width: 100%;
  font-size: 1rem;

  &:focus {
    border-color: var(--primary-color);
  }

  &.full {
    width: 100%;
  }

  &.error {
    border-color: var(--danger-color);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
