<template>
  <FormGroup class="files-input" :title="label" :shortTitle="shortTitle" :required="required" :help="help">
    <Dropzone ref="dropzone" :options="dropzoneOptions" @remove="handleRemove" @add="handleInput" :v="v" />
    <FormError v-if="v && v.$error" :v="v" />
  </FormGroup>
</template>

<script>
import Dropzone from './Dropzone'
import FormGroup from './FormGroup'
import FormError from './FormError'
export default {
  name: 'FilesInput',
  components: {
    FormError,
    FormGroup,
    Dropzone
  },
  props: {
    value: [Array, String],
    label: String,
    dropzoneOptions: Object,
    required: Boolean,
    v: Object,
    help: String,
    shortTitle: String
  },
  mounted () {
    this.files = (this.value || '').split(';')
    this.$refs.dropzone.addFiles(this.files)
  },
  data () {
    return {
      files: []
    }
  },
  methods: {
    handleInput (file) {
      this.files.push(file)
      this.$emit('input', this.files.join(';'))
    },
    handleRemove (file) {
      const index = this.files.indexOf(file)
      this.files.splice(index, 1)
      this.$emit('input', this.files.join(';'))
    },
    upload (data) {
      this.$refs.dropzone.processQueue(data)
    }
  }
}
</script>
