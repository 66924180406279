<template>
  <FormGroup :title="label" :shortTitle="shortTitle" :help="v && v.$error && original ? $t('Origineel antwoord: ') + original : help" :v="v" :for-id="id">
    <CombinedInput>
      <Select :options="countries" v-model="country" />
      <Input :value="country" :disabled="true" :size="country.length" small-padding center :v="v" hideError />
      <Input type="tel" v-model="number" :v="v" placeholder="xxx xx xx xx" :mask="['BE', 'NL', 'FR', 'DE'].includes(country) ? ['P## ## ## ##', 'P# ## ## ##'] : false" hideError />
<!--      <Input :id="id" type="tel" v-model="number" :mask="['P## ## ## ##', 'P# ## ## ##']" :tokens="phoneTokens" placeholder="xxx xx xx xx" :v="v" hideError />-->
    </CombinedInput>
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
import CombinedInput from './CombinedInput'
import Select from './Select'
import Input from './Input'
import phoneCodes from '@/assets/phone-codes.json'
export default {
  name: 'Phone',
  data () {
    return {
      country: '+32',
      number: false,
      original: false,
      phoneTokens: {
        P: {
          pattern: /[1-9]/
        },
        '#': {
          pattern: /\d/
        }
      }
    }
  },
  props: {
    label: String,
    value: String,
    v: Object,
    help: String,
    id: String,
    shortTitle: String
  },
  components: {
    Input,
    Select,
    CombinedInput,
    FormGroup
  },
  mounted () {
    if (this.value && this.value.length > 0) {
      // Save original to show in help
      this.original = this.value
      if (!this.value.includes('+')) {
        this.number = this.value
      } else {
        // Figure out country code
        this.country = this.value.split(' ')[0]
        // Set the number
        this.number = this.value.substr(this.country.length)
      }
      if (this.v) {
        this.v.$touch()
      }
    }
  },
  computed: {
    countries () {
      return phoneCodes
        .map(phoneCode => {
          return {
            value: phoneCode.dial_code,
            label: phoneCode.code
          }
        }).sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)
    },
    phoneNumber () {
      if (!this.country || !this.number) return ''
      return `${this.country.trim()} ${this.number.trim()}`
    }
  },
  watch: {
    phoneNumber () {
      this.$emit('input', this.phoneNumber)
      if (this.v) this.v.$touch()
    }
  }
}
</script>
