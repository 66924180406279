<template>
  <Select :options="countries" :v="v" :value="value" :help="help" :shortTitle="shortTitle" @input="value => $emit('input', value)" simple :label="$t('Land')" />
</template>

<script>
import Select from './Select'
import api from '../../api'

export default {
  name: 'Country',
  props: ['value', 'v', 'help', 'id', 'shortTitle'],
  components: {
    Select
  },
  data () {
    return {
      countries: []
    }
  },
  async mounted () {
    const response = await api.get('get_countries')
    this.countries = response.data.result.map(country => {
      return {
        value: country,
        label: country
      }
    })
  }
}
</script>
